<template>
  <v-footer color="#0f0f0f" dark min-height="300">
    <v-row
      justify="center"
      no-gutters
    >
    <v-col cols="12" md="8" class="text-center mt-5 pt-5">
      <v-btn class="ma-1" icon large target="_blank" href="https://www.facebook.com/profile.php?id=100094049551423"><v-icon>mdi-facebook</v-icon></v-btn>
      <v-btn class="ma-1" icon large target="_blank" href="https://www.instagram.com/la_monnaie_pirate/"><v-icon>mdi-instagram</v-icon></v-btn>
      <v-btn class="ma-1" icon large target="_blank" href="https://discord.gg/aQ34zjvFmC">
        <img src="../../assets/img/homepage/discord-w.png" class="d-block" height="28" contain/>
      </v-btn>
      <br><br>
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" md="8" class="text-center mt-5 pt-5">
      <v-btn class="ma-1" text rounded to="/">Accueil</v-btn>
      <v-btn class="ma-1" text rounded to="/game/battle/menu">Bataille navale</v-btn>
      <v-btn class="ma-1" text rounded to="/book">Journal de bord</v-btn>
      <template v-if="!$store.state.auth.isLogin">
        <v-btn class="ma-1" text rounded to="/register">S'inscrire</v-btn>
        <v-btn class="ma-1" text rounded to="/login">Se connecter</v-btn>
      </template>
      <v-btn class="ma-1" text rounded to="/contact">Contact</v-btn>
      <br class="d-none d-sm-inline">
      <!-- <v-btn class="ma-1" text rounded to="/faq">FAQ</v-btn> -->
      <v-btn class="ma-1" text rounded to="/cgu">CGU</v-btn>
      <v-btn class="ma-1" text rounded to="/cgv">CGV</v-btn>
    </v-col>
    
    <v-col cols="12" class="text-center primary--text">
      <span class="font-main" style="font-size:45px;font-weight:800!important;">{{ $store.state.app.siteName }}</span>
      <br><br><br><br><br><br>
      <br>
    </v-col>

    </v-row>
  </v-footer>
</template>

<script>

//import router from '../../router/router'

export default {
  name: 'pix-footer',

  data: () => ({
  }),
  
  mounted: function(){
  },
  
  methods: {
  },

  computed:{
  }
    
};
</script>
